import React from "react"

const QRMenu = ({handleChange}) => {
    let dmenu = [
    {
      lang: "English",
      gen: "English",
      id: "english"
    },
    {
      lang: "Spanish",
      gen: "Español",
      id: "spanish"
    },
    {
      lang: "Vietnamese",
      gen: "Tiếng Việt",
      id: "vietnamese"
    },
    {
      lang: "Chinese",
      gen: "中文",
      id: "chinese"
    },
    {
      lang: "Korean",
      gen: "한국어",
      id: "korean"
    },
    {
      lang: "Farsi",
      gen: "یسراف",
      id: "farsi"
    }
  ]

  let menu = dmenu.map(({lang, gen, id}, idx)=>{
    return (
      <li key={idx}>
          <a id={id} href="/" className={`${idx == 0 ? 'active': ''}`} onClick={(e)=>{
            //This whole thing could just be a setState
              e.preventDefault();
              console.log(e.target.tagName)
              let qr = document.getElementById('qr-menu');
              Array.prototype.forEach.call(qr.children, (child)=>{     
                child.children[0].classList.remove('active') 
              })
            
              if(e.target.tagName == 'A') {
                e.target.classList.add('active');
              }else {
                e.target.closest('a').classList.add('active')
              }
              handleChange(id);
          }}>
          <p>{gen}</p>
          <p className="ttu langlittle">{lang}</p>
          </a>
      </li>
    )
  })

  return (
    <ul id="qr-menu" className="qr-menu">
        {menu}  
    </ul>
  )
}

export default QRMenu