import React, {Component} from "react"
import SEO from "../components/seo"
import { CSSTransition } from 'react-transition-group' // ES6
import Layout from "../components/layout"
import QRMenu from "../components/qrMenu"
import "../sass/qr.scss"
import radnetLogo from "../images/logo-radnet.png"
import hand from "../images/hand.svg"
import facecovering from "../images/facecovering.svg"
import flexWarning from "../images/flex-warning.svg"

let pageData = [{
  english: {
    subtitle: "Have you been diagnosed with COVID-19?",
    attention: "If yes, please call us\nbefore you enter",
    checkIn: "REMOTE CHECK-IN",
    checkInInfo: "To use our remote check-in option please text us and we’ll reply with links for you to complete check-in from outside the center.",
    faceCovering: "FACE COVERINGS",
    faceCoveringInfo: "Please be aware that our staff will be wearing masks and we ask you to wear a face covering as well. If you do not have a face covering and our supply allows, we will provide you with one.",
    callText: "Call or Text the number listing on the front door."
  },
  spanish: {
    subtitle:"¿Le han diagnosticado COVID-19?",
    attention: "En caso afirmativo,\nllámenos antes de entrar",
    checkIn:"CHEQUEO REMOTO",
    checkInInfo:"Para utilizar nuestra opción de registro remoto, envíenos un mensaje de texto y le responderemos con enlaces para que complete el registro desde fuera del centro.",
    faceCovering: "REVESTIMIENTOS",
    faceCoveringInfo: "Tenga en cuenta que nuestro personal llevará máscaras y le pedimos que también se cubra la cara. Si no tiene una cubierta facial y nuestro suministro lo permite, le proporcionaremos una.",
    callText: "Llame o envíe un mensaje de texto al número que aparece en la puerta principal."
  },
  vietnamese: {
    subtitle:"Bạn đã được chẩn đoán mắc COVID-19?",
    attention: "NẾU CÓ, HÃY GỌI CHO CHÚNG\nTÔI TRƯỚC KHI BẠN VÀO",
    checkIn:"XÓA KIỂM TRA",
    checkInInfo:"Để sử dụng tùy chọn đăng ký từ xa của chúng tôi, vui lòng nhắn tin cho chúng tôi và chúng tôi sẽ trả lời với các liên kết để bạn hoàn thành đăng ký từ bên ngoài trung tâm.",
    faceCovering: "Bao bì mặt",
    faceCoveringInfo: "Xin lưu ý rằng nhân viên của chúng tôi sẽ đeo mặt nạ và chúng tôi yêu cầu bạn cũng phải che mặt. Nếu bạn không có mặt che mặt và nguồn cung của chúng tôi cho phép, chúng tôi sẽ cung cấp cho bạn một mặt.",
    callText: "Gọi hoặc nhắn tin danh sách số trên cửa trước."
  },
  chinese: {
    subtitle:"您被診斷出患有COVID-19嗎？",
    attention: "如果是，\n請在輸入之前致電給我們",
    checkIn:"遠程入住",
    checkInInfo:"要使用我們的遠程值機選項，請給我們發短信，我們將通過鏈接回复您，以完成中心外的值機。",
    faceCovering: "面罩",
    faceCoveringInfo: "請注意，我們的工作人員將戴口罩，我們也請您戴上口罩。 如果您沒有面罩並且我們的供應允許，我們將為您提供一個。",
    callText: "給前門上的電話號碼打電話或發短信。"
  },
  korean: {
    subtitle:"COVID-19 진단을 받았습니까?",
    attention: "그렇다면,\n입력하기 전에 미국에 전화하십시오",
    checkIn:"원격 체크인",
    checkInInfo:"원격 체크인 옵션을 사용하려면 문자 메시지를 보내 주시면 센터 외부에서 체크인을 완료 할 수있는 링크가 포함 된 답장을 보내 드리겠습니다.",
    faceCovering: "얼굴 덮개",
    faceCoveringInfo: "직원이 마스크를 착용하고 얼굴 가리개를 착용하도록 요청합니다. 얼굴 가리개가없고 공급이 허용되는 경우 얼굴 덮개를 제공합니다.",
    callText: "정문에있는 번호 목록에 전화를 걸거나 문자를 보내십시오."
  },
  farsi: {
    subtitle:"آیا مبتلا به COVID-19 شده اید؟",
    attention: "اگر بله ، لطفا قبل از وارد کردن ، با ما تماس بگیرید",
    checkIn:"حذف چک در",
    checkInInfo:"برای استفاده از گزینه ورود به سیستم از راه دور ، لطفاً برای ما متن ارسال کنید و ما برای پیوستن چک از خارج از مرکز با پیوندهایی به شما پاسخ خواهیم داد.",
    faceCovering: "پوشش ",
    faceCoveringInfo: "لطفاً توجه داشته باشید كه كاركنان ما ماسك خواهند داشت و از شما می خواهیم كه پوششی از صورت نیز داشته باشید. اگر روتختی ندارید و تأمین ما اجازه می دهد ، ما یکی از آنها را برای شما فراهم می کنیم.",
    callText: "با شماره تلفن در جلو تماس بگیرید یا متن را بنویسید."
  }
}]

class PageTemplate extends Component {
  constructor(props){
    super(props)
    this.state = {
      language: "english",
      pageChange: true
    }
    this.changeLang = this.changeLang.bind(this)
  }
  changeLang(id){
    console.log(id)

    this.setState({pageChange: false}, ()=>{
      setTimeout(() => {
        this.setState({pageChange: true})
        this.setState({language: id})
      }, 300);
    })

    
  }
  render(){

    return (
      <Layout>
        <SEO title="Global" />
        <div className="qr tc">
          <img src={radnetLogo} style={{maxWidth: `220px`}} />
          <QRMenu handleChange={this.changeLang}/>
          <div className="qr-post mt5">
            {pageData.map(({[this.state.language]: {subtitle, attention, checkIn, checkInInfo, faceCovering, faceCoveringInfo, callText}}, idx) => {
              return (
                (
                  <CSSTransition
                    key={idx}
                    in={this.state.pageChange}
                    classNames="example"
                    timeout={500}
                    >
                    <div>
                    <h2 className="subtitle pb2">{subtitle}</h2> 
                    <div className="pb5 attention mb5">
                        <p><img src={hand} /></p>
                        <p className="ttu" style={{whiteSpace:`pre-wrap`}}>{attention}</p>
                    </div>
                    <hr />
                    <div className="cf mt4">
                      <p className="fl-m fl-l qr-heading"><img src={flexWarning} /></p>
                      <h2 className="ttu checkIn ">{checkIn}</h2>
                    </div>
                      <p className="checkInInfo mb5">{checkInInfo}</p>
                    
                   <hr />
                   <div className="cf mt4">
                    <p className="fl-m fl-l qr-heading"><img src={facecovering} /></p>
                    <h2 className="faceCovering ">{faceCovering}</h2>
                    </div>
                    <p className="faceCoveringInfo">{faceCoveringInfo}</p>
        
                    <h2 className="callText mt5">{callText}</h2>
                    </div>
                  </CSSTransition>
                )
              )
            })}
          </div>
        </div>
      </Layout>
    )
  }
}

export default PageTemplate